/* GT ULTRA FONTS */
@font-face {
  font-family: 'GT Ultra Fine';
  src: url('./fonts/GTUltraFine-Ultra.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Regular';
  src: url('./fonts/GTUltra-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
