/* Navbar Styling */
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.75rem 3rem 1.5rem 3rem;
  color: var(--green);
  background-color: var(--black);
}

.title {
  color: var(--green);
  font-size: var(--fs-xxl);
  text-decoration: none;
  font-family: 'GT Ultra Fine', 'GT Regular', sans-serif;
}

.title:hover {
  cursor: pointer;
}

/* Buttons */
.github {
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.github:hover {
  transform: scale(1.1);
  transition: all 200ms ease-in-out;
}

.github svg {
  fill: var(--green);
  width: var(--fs-xl);
  height: var(--fs-xl);
}

/* Wave Styling */
.wave {

}

.wave svg {
  position: relative;
  display: block;
  /* width: calc(139% + 1.3px); */
  width: 100%;
  max-width: 100%;
  height: 1.5rem;
  fill: var(--black);
}

/* Media Query for Mobile Devices below 480px in width */
@media (max-device-width:480px) {
  .navbar {
    padding: 1.5rem 2rem;
  }

  .navbar h1 {
    font-size: var(--fs-xxl);
  }

  /* Wave Styling */
  .wave svg {
    height: 0.75rem;
  }
}
