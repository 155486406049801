/* Content Styling */
.home {
  width: 100%;
  margin-top: 3rem;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
}

.header h2 {
  font-size: var(--fs-xl);
  text-align: center;
  margin-bottom: 1rem;
}

.header span {
  font-size: var(--fs-base);
  text-align: center;
}

.header a {
  color: var(--black);
  text-decoration: none;
}

.searchbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
  padding-top: 0;
}

/* Link Underline Styling */
.underline {
  position: relative;
  text-decoration: none;
  display: inline-block;
}

.underline:after {
  display: block;
  content: '';
  border-bottom: 1px solid var(--black);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 100% 50%
}

.underline:hover:after {
  transform: scaleX(1);
  transform-origin: 0 50%;
}

/* Media Query for Mobile Devices below 480px in width */
@media (max-device-width:480px) {

  .home {
    margin-top: 0rem;
  }

  /* Header Styling */
  .header h2 {
    font-size: var(--fs-xxl);
  }

  .header span {
    font-size: var(--fs-md);
  }
}
